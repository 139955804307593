
import Vue from 'vue';

export default {
  data() {
    return {
      lastTimeStamp: Date.now()
    };
  }, // data
  mounted() {
    Vue.prototype?.$mixpanel?.track('queue_report', {
      referrer: document.referrer
    });
  },
  methods: {
    refreshPage() {
      this.lastTimeStamp = Date.now();
    } // refreshPage
  } // methods
}; // export default
